import React from "react";
import EddieTimeline from "../components/ed-timeline";

export const Edward = () => {
	return (
		<React.Fragment>
			<h1>Edward Vandenburgh</h1>

			<EddieTimeline />
		</React.Fragment>
	);
}
